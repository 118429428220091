import getConfig from 'next/config'
import { useRouter } from 'next/router'
import featureSwitches from '@/enums/feature-switches'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'

const useEnableNewBrunchTicketing = () => {
  const {
    publicRuntimeConfig: {
      BRUNCH_CONFIG: { BRUNCH_PARENT_EVENT_ID },
    },
  } = getConfig()
  const { query } = useRouter()
  const enableNewBrunchTicketing = useFeatureSwitch(
    featureSwitches.enableNewBrunchTicketing
  )

  if (!enableNewBrunchTicketing) {
    return false
  }

  if (query?.idOrCountry && query.idOrCountry === BRUNCH_PARENT_EVENT_ID) {
    return true
  }

  return false
}

export default useEnableNewBrunchTicketing
