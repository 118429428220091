import { useEffect } from 'react'
import { colors } from '@resident-advisor/design-system'
import { useMenuContext, actions } from '@/context/MenuContext'

const useMenuColors = (bg) => {
  const [, dispatch] = useMenuContext()

  useEffect(() => {
    if (bg) {
      dispatch({
        type: actions.SET_HOVER_COLOR,
        payload: getHoverColor(bg),
      })
    }
    return () => {
      dispatch({ type: actions.RESET_HOVER_COLOR })
    }
  }, [dispatch, bg])

  useEffect(() => {
    if (bg) {
      dispatch({
        type: actions.SET_INDICATOR_VARIANT,
        payload: getIndicatorVariant(bg),
      })
    }
    return () => {
      dispatch({ type: actions.RESET_INDICATOR_VARIANT })
    }
  }, [dispatch, bg])
}

const headerBackgroundColors = {
  ACCENT: 'accent',
}

const getHoverColor = (bg) => {
  switch (bg) {
    case headerBackgroundColors.ACCENT:
      return colors.grey['10']
    default:
      return colors.coral
  }
}
const getIndicatorVariant = (bg) => {
  switch (bg) {
    case headerBackgroundColors.ACCENT:
      return 'secondary'
    default:
      return 'primary'
  }
}

export default useMenuColors
