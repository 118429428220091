import styled from 'styled-components'
import { Text, Alignment, variants } from '@resident-advisor/design-system'
import Skeleton from 'react-loading-skeleton'
import unescape from 'lodash/unescape'
import getConfig from 'next/config'
import arrayHasData from 'lib/arrayHasData'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import Breadcrumb from '@/components/generic/breadcrumb'
import Heading from '@/components/generic/heading'

const {
  publicRuntimeConfig: { HEADER_CONFIG },
} = getConfig()

const formatText = (text) => {
  if (text && typeof text === 'string') {
    return unescape(text)
  }

  return text
}

const getTextVariant = (text, dynamicFontSize) =>
  dynamicFontSize && text?.length >= HEADER_CONFIG.LONG_TITLE_CHAR_COUNT
    ? variants.text.longHeading
    : variants.text.heading.xl

const HeaderContent: React.FC<HeaderContentProps> = ({
  breadcrumb,
  tags,
  text,
  dynamicFontSize,
  isArchivedPage,
  Component,
}: HeaderContentProps) => {
  const textVariant = getTextVariant(text, dynamicFontSize)

  return (
    <Alignment flexDirection="column" flex={1} position="relative" pt={3}>
      {arrayHasData(breadcrumb) && (
        <BreadcrumbWrapper>
          <Breadcrumb links={breadcrumb} tags={tags} />
        </BreadcrumbWrapper>
      )}
      <Alignment flexDirection="row" justifyContent="space-between">
        {Component || (
          <Heading level={1} m={0} width="100%">
            <Text
              color={isArchivedPage ? 'tertiary' : 'primary'}
              variant={textVariant}
            >
              {text ? formatText(text) : <Skeleton count={1} />}
            </Text>
          </Heading>
        )}
      </Alignment>
    </Alignment>
  )
}

type BreadCrumProps = {
  href?: string | { pathname: string; query?: NextParsedUrlQuery }
  as?: string | { pathname: string; query?: NextParsedUrlQuery }
  text: string
  component?: React.ReactNode | JSX.Element
}

type TagProps = {
  href: string | { pathname: string; query: string | object }
  as?: string | { pathname: string; query: string | object }
  text: string
}

type HeaderContentProps = {
  isArchivedPage?: boolean
  text?: React.ReactNode
  breadcrumb?: BreadCrumProps[]
  tags?: TagProps[]
  dynamicFontSize?: boolean
  Component?: React.ReactNode
}

const BreadcrumbWrapper = styled.div`
  position: absolute;
  top: 0;
`

BreadcrumbWrapper.displayName = 'BreadcrumbWrapper'

export default HeaderContent
