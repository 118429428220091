import PropTypes from 'prop-types'
import arrayHasData from 'lib/arrayHasData'
import styled from 'styled-components'
import {
  Alignment,
  ArrowLeftIcon,
  Box,
  Tag,
  Icon,
  CircleIcon,
  variants,
} from '@resident-advisor/design-system'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import Flag from '@/components/shared/flag'
import Link, { hrefAsPropTypes } from '@/components/generic/link'
import BreadcrumbJsonLd from './BreadcrumbJsonLd'

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  links,
  tags,
}: BreadcrumbProps) => (
  <>
    <BreadcrumbJsonLd links={links} />
    <nav aria-label="Breadcrumb">
      <Alignment as="ul" flexDirection="row" alignItems="center">
        {links.map((link, i) => (
          <Alignment
            as="li"
            key={`${link.text}-${link.href}`}
            justifyContent="flex-end"
            alignItems="center"
          >
            {i !== 0 && <LinkSeparator />}
            <BreadcrumbLink
              href={link.href}
              as={link.as}
              hoverColor={link.hoverColor}
            >
              {i === 0 && <Icon size={12} mr={1} Component={ArrowLeftIcon} />}
              {link.icon ?? null}
              {link.text}
            </BreadcrumbLink>
          </Alignment>
        ))}
        {arrayHasData(tags) &&
          tags.map((tag) => (
            <Alignment
              as="li"
              key={`${tag.text}-${tag.href}`}
              justifyContent="flex-end"
            >
              <LinkSeparator isTag />
              <BreadcrumbTag {...tag} />
            </Alignment>
          ))}
      </Alignment>
    </nav>
  </>
)

type BreadcrumbTagProps = {
  href: string | { pathname: string; query: string | object }
  as?: string | { pathname: string; query: string | object }
  text: string
}

type BreadcrumbLinkProps = {
  href?: string | { pathname: string; query?: NextParsedUrlQuery }
  as?: string | { pathname: string; query?: NextParsedUrlQuery }
  text: string
  icon?: JSX.Element
  hoverColor?: string
}

type BreadcrumbProps = {
  links?: BreadcrumbLinkProps[]
  tags?: BreadcrumbTagProps[]
}

const LinkSeparator: React.FC<LinkSeparatorProps> = ({
  isTag,
}: LinkSeparatorProps) =>
  isTag ? (
    <Box mx={1} />
  ) : (
    <Icon size={6} mx={2} Component={CircleIcon} color="primary" />
  )

type LinkSeparatorProps = {
  isTag?: boolean
}
LinkSeparator.propTypes = {}

const BreadcrumbLink = ({ children, href, as, hoverColor }) => (
  <StyledLink
    variant={variants.text.label}
    href={href}
    forwardedAs={as}
    hoverColor={hoverColor}
  >
    {children}
  </StyledLink>
)

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
`

BreadcrumbLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: hrefAsPropTypes.isRequired,
  as: hrefAsPropTypes,
  hoverColor: PropTypes.string,
}

const BreadcrumbTag = ({ text, href, as }) => (
  <TagLink noStyle href={href} forwardedAs={as}>
    <Tag>{text}</Tag>
  </TagLink>
)

const TagLink = styled(Link)`
  text-decoration: none;
`

BreadcrumbTag.propTypes = {
  text: PropTypes.string.isRequired,
  href: hrefAsPropTypes.isRequired,
  as: hrefAsPropTypes,
}

const BreadcrumbFlagIcon = ({ urlCode }: { urlCode: string }) => (
  <Flag size={17} urlCode={urlCode} mr={2} />
)

const BreadcrumbProIcon = () => <Icon size={17} Component={CircleIcon} mr={2} />

export type { BreadcrumbLinkProps, BreadcrumbTagProps }
export { BreadcrumbLink, BreadcrumbFlagIcon, BreadcrumbProIcon }
export default Breadcrumb
